export const optionsByDia = {
  '15': [0.6, 1.5],
  '20': [1.5, 2.5],
  '25': [3.5, 6],
  '32': [6],
  '40': [10],
  '50': [15, 35],
  '65': [25, 50],
  '80': [40, 80],
  '100': [60, 120],
  '125': [100, 200],
  '150': [150, 300],
  '200': [500]
};

export const optionsByDia3 = {
  '15': [1.5],
  '20': [2.5],
  '25': [3.5],
  '32': [6],
  '40': [10],
  '50': [45],
  '65': [60],
  '80': [120],
  '100': [150],
  '125': [250],
  '150': [250],
  '200': [500]
};

export const kran_opt = [
  { 'id': 15, code: 'Н00002390', desc: 'Кран шаровой для монтажа термопреобразователя Ду 15', text: 'Ду 15', paid: true, dia: [15] },
  { 'id': 20, code: 'Н00002391', desc: 'Кран шаровой для монтажа термопреобразователя Ду 20', text: 'Ду 20', paid: true, dia: [20] },
  { 'id': 25, code: 'Н00004977', desc: 'Гильза для монтажа термопреобразователя в обратный трубопровод Ду 25 - 32', text: 'Ду 25 - 32', paid: true, dia: [25, 32] },
  { 'id': 40, code: 'Н00031902', desc: 'Гильза для монтажа термопреобразователя в обратный трубопровод Ду 40 - 80', text: 'Ду 40 - 80', paid: true, dia: [40, 50, 65] },
  { 'id': 100, code: 'Н00031903', desc: 'Гильза для монтажа термопреобразователя в обратный трубопровод Ду 100 - 150', text: 'Ду 100 - 150', paid: true, dia: [15, 20, 25, 32, 40, 50, 65] },
  { 'id': 200, code: 'Н00031905', desc: 'Гильза для монтажа термопреобразователя в обратный трубопровод Ду 200', text: 'Ду 200', paid: true, dia: [15, 20, 25, 32, 40, 50, 65] },
];
export const kran_opt_special = [
  { 'id': 80, code: 'Н10028524', desc: 'Гильза для монтажа термопреобразователя в обратный трубопровод Ду 80-200', text: 'Ду 80 - 200', paid: true, dia: [80, 100, 125, 150, 200] },
];

export const rezba_opt = [
  { 'id': 15, text: 'Н00001460 Ду 15', paid: true },
  { 'id': 20, text: 'Н00001458 Ду 20', paid: true },
  { 'id': 25, text: 'Н00001598 Ду 25', paid: true },
  { 'id': 32, text: 'Н00001666 Ду 32', paid: true },
  { 'id': 40, text: 'Н00002106 Ду 40', paid: true },
  { 'id': 50, text: 'Н00004073 Ду 50', paid: true },
];

export const privarka_opt = [
  { 'id': 15, text: 'Н00004059 Ду 15', paid: true },
  { 'id': 20, text: 'Н00004060 Ду 20', paid: true },
  { 'id': 25, text: 'Н00004061 Ду 25', paid: true },
  { 'id': 32, text: 'Н00004062 Ду 32', paid: true },
  { 'id': 40, text: 'Н00004063 Ду 40', paid: true },
];

export const radiator_opt = [
  { 'id': 1, text: 'Радиатор ЮТЛИ внут М20x1.5 / внеш M20x1.5 L = 81.5', desc: 'Радиатор ЮТЛИ.713565.019-00 Внутренняя резьба М20x1.5 / Внешняя резьба M20x1.5 L = 81.5мм (ст. 14X17H2)', code: 'Н00001911', paid: true },
  { 'id': 2, text: 'Радиатор ЮТЛИ внут G1/2" / внеш M20x1.5 L = 81.5', desc: 'Радиатор ЮТЛИ.713565.019-01 Внутренняя резьба G1/2" / Внешняя резьба M20x1.5 L = 81.5мм (ст. 14X17H2)', code: 'Н00021686', paid: true },
  { 'id': 3, text: 'Радиатор ЮТЛИ внут М20x1.5 / внеш G1/2" L = 81.5', desc: 'Радиатор ЮТЛИ.713565.019-02 Внутренняя резьба М20x1.5 / Внешняя резьба G1/2" L = 81.5мм (ст. 14X17H2)', code: 'Н00001912', paid: true },
  { 'id': 4, text: 'Радиатор ЮТЛИ внут G1/2" / внеш G1/2" L = 81.5', desc: 'Радиатор ЮТЛИ.713565.019-03 Внутренняя резьба G1/2" / Внешняя резьба G1/2" L = 81.5мм (ст. 14X17H2)', code: 'Н00001913', paid: true },
  { 'id': 5, text: 'Радиатор ЮТЛИ внут М20x1.5 / внеш M20x1.5 L = 163', desc: 'Радиатор ЮТЛИ.713565.019-04 Внутренняя резьба М20x1.5 / Внешняя резьба M20x1.5 L = 163мм (ст. 14X17H2)', code: 'Н00016353', paid: true },
  { 'id': 6, text: 'Радиатор ЮТЛИ внут G1/2" / внеш M20x1.5 L = 163', desc: 'Радиатор ЮТЛИ.713565.019-05 Внутренняя резьба G1/2" / Внешняя резьба M20x1.5 L = 163мм (ст. 14X17H2)', code: 'Н00021678', paid: true },
  { 'id': 7, text: 'Радиатор ЮТЛИ внут М20x1.5 / внеш G1/2" L = 163', desc: 'Радиатор ЮТЛИ.713565.019-06 Внутренняя резьба М20x1.5 / Внешняя резьба G1/2" L = 163мм (ст. 14X17H2)', code: 'Н00016355', paid: true },
  { 'id': 8, text: 'Радиатор ЮТЛИ внут G1/2" / внеш G1/2" L = 163', desc: 'Радиатор ЮТЛИ.713565.019-07 Внутренняя резьба G1/2" / Внешняя резьба G1/2" L = 163мм (ст. 14X17H2)', code: 'Н00016354', paid: true },
];

export const Monthes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const Days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
  '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
  '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
export const Temps = ['0', '3', '5', '7', '8', '10', '15'];

export const Table2 = [
  { du: 15, maxim: 0.6, minim: 0.012, limit: 1.2, por: 0.004, mass: 885 },
  // { du: 15,  maxim: 1.0  , minim: 0.020, limit: 2.0, por: 0.006, mass: 885},

  //{ du: 15, maxim: 1.5, minim: 0.030, limit: 3.0, por: 0.008, mass: 885 },
  { du: 15, maxim: 1.5, minim: 0.015, limit: 3.5, por: 0.003, mass: 885 },

  //{ du: 20, maxim: 2.5, minim: 0.050, limit: 5.0, por: 0.015, mass: 965 },
  { du: 20, maxim: 2.5, minim: 0.025, limit: 6.0, por: 0.005, mass: 965 },
  { du: 20, maxim: 1.5, minim: 0.030, limit: 3.0, por: 0.006, mass: 965 },

  { du: 25, maxim: 3.5, minim: 0.035, limit: 7, por: 0.007, mass: 965 },
  { du: 25, maxim: 6.0, minim: 0.060, limit: 12, por: 0.020, mass: 965 },

  { du: 32, maxim: 6.0, minim: 0.060, limit: 15, por: 0.012, mass: 995 },

  { du: 40, maxim: 10.0, minim: 0.100, limit: 20, por: 0.020, mass: 1510 },

  { du: 50, maxim: 15.0, minim: 0.150, limit: 30, por: 0.030, mass: 7100 },
  { du: 50, maxim: 35.0, minim: 0.350, limit: 70, por: 0.070, mass: 7100 },

  { du: 65, maxim: 25.0, minim: 0.250, limit: 50, por: 0.050, mass: 9300 },
  { du: 65, maxim: 50.0, minim: 0.500, limit: 100, por: 0.070, mass: 9300 },

  { du: 80, maxim: 40.0, minim: 0.400, limit: 80, por: 0.080, mass: 11500 },
  { du: 80, maxim: 80.0, minim: 0.800, limit: 160, por: 0.100, mass: 11500 },

  { du: 100, maxim: 60.0, minim: 0.600, limit: 120, por: 0.150, mass: 13600 },
  { du: 100, maxim: 120.0, minim: 1.200, limit: 240, por: 0.200, mass: 13600 },

  { du: 125, maxim: 100.0, minim: 1.000, limit: 200, por: 0.240, mass: 18500 },
  { du: 125, maxim: 200.0, minim: 2.000, limit: 400, por: 0.280, mass: 18500 },

  { du: 150, maxim: 150.0, minim: 1.500, limit: 300, por: 0.300, mass: 28200 },
  { du: 150, maxim: 300.0, minim: 3.000, limit: 600, por: 0.350, mass: 28200 },

  { du: 200, maxim: 500.0, minim: 2.000, limit: 1000, por: 0.350, mass: 37500 }
];

export const Table3 = [
  { du: 15, maxim: 3.0, minim: 0.015, nom: 1.5, por: 0.003, per: 0.023 },
  { du: 20, maxim: 5.0, minim: 0.025, nom: 2.5, por: 0.005, per: 0.038 },
  { du: 25, maxim: 7.0, minim: 0.035, nom: 3.5, por: 0.007, per: 0.053 },
  { du: 32, maxim: 12.0, minim: 0.06, nom: 6.0, por: 0.012, per: 0.09 },
  { du: 40, maxim: 20.0, minim: 0.1, nom: 10.0, por: 0.02, per: 0.15 },

  { du: 50, maxim: 60.0, minim: 0.27, nom: 45.0, por: 0.054, per: 0.9 },
  { du: 50, maxim: 60.0, minim: 0.45, nom: 45.0, por: 0.009, per: 3.0 },
  { du: 50, maxim: 60.0, minim: 0.09, nom: 45.0, por: 0.018, per: 0.225 },

  { du: 65, maxim: 90.0, minim: 0.36, nom: 60.0, por: 0.072, per: 1.0 },
  { du: 65, maxim: 90.0, minim: 0.6, nom: 60., por: 0.12, per: 4.0 },
  { du: 65, maxim: 90.0, minim: 0.12, nom: 60., por: 0.024, per: 0.3 },

  { du: 80, maxim: 240.0, minim: 0.6, nom: 120.0, por: 0.126, per: 1.0 },
  { du: 80, maxim: 240.0, minim: 0.9, nom: 120.0, por: 0.18, per: 6 },
  { du: 80, maxim: 240.0, minim: 0.18, nom: 120.0, por: 0.036, per: 0.45 },

  { du: 100, maxim: 300.0, minim: 0.9, nom: 150.0, por: 0.18, per: 1.8 },
  { du: 100, maxim: 300.0, minim: 1.5, nom: 150.0, por: 0.3, per: 10.0 },
  { du: 100, maxim: 300.0, minim: 0.3, nom: 150.0, por: 0.06, per: 0.75 },


  { du: 125, maxim: 500.0, minim: 1.35, nom: 250.0, por: 0.27, per: 2.0 },
  { du: 125, maxim: 500.0, minim: 2.25, nom: 250.0, por: 0.45, per: 15.0 },
  { du: 125, maxim: 500.0, minim: 0.45, nom: 250.0, por: 0.09, per: 1.125 },

  { du: 150, maxim: 500.0, minim: 1.8, nom: 250.0, por: 0.36, per: 4.0 },
  { du: 150, maxim: 500.0, minim: 3.0, nom: 250.0, por: 0.6, per: 20.0 },
  { du: 150, maxim: 500.0, minim: 0.6, nom: 250.0, por: 0.12, per: 1.5 },

  { du: 200, maxim: 1000.0, minim: 4.0, nom: 500.0, por: 0.9, per: 6.0 },
  { du: 200, maxim: 1000.0, minim: 7.5, nom: 500.0, por: 1.5, per: 50.0 },
  { du: 200, maxim: 1000.0, minim: 1.5, nom: 500.0, por: 0.3, per: 3.75 },
]

export const typeTableByCount =
{
  '1': [
    {
      k: 1,
      t: '02',
      i: '/4_1.png',
      f: 'Q=M₁×(h₁-h₂)',
      s: 1,
      m: ['0'],
      e: ['0']
    },
    {
      k: 2,
      t: '02',
      i: '/4_2.png',
      f: 'Q=M₁×(h₂-h₁)',
      s: 2,
      m: ['0'],
      e: ['0']
    },
    {
      k: 3,
      t: '04',
      i: '/4_3.png',
      f: 'Q=M₁×(h₁-hₓ)',
      s: 3,
      m: ['0'],
      e: ['0']
    },
  ],
  '2': [
    {
      k: 1,
      t: '05',
      i: '/5.png',
      f: 'Q=M₁×(h₁-h₂)',
      m: ['0', '2', '4'],
      e: ['0']
    },
    {
      k: 2,
      t: '06',
      i: '/6.png',
      f: 'Q=M₁×(h₁-h₂)+(M₁-M₂)×(h₂-hₓ)',
      m: ['0', '1', '3'],
      e: ['0', '1', '2', '3']
    },
    {
      k: 3,
      t: '07',
      i: '/7.png',
      f: 'Q₁=M₁×(h₁-h₂); Q₂=(M₁-M₂)×(h₂-hₓ)',
      m: ['0', '1', '3'],
      e: ['0', '1', '2', '3']
    },
    {
      k: 4,
      t: '08',
      i: '/8.png',
      f: 'Q=M₁×(h₁-hₓ)-M₂×(h₂-hₓ)',
      m: ['0', '1', '3'],
      e: ['0']
    }
  ],
  '3': [
    {
      k: 1,
      t: '09',
      i: '/9.png',
      f: 'Q=M₁×(h₁-h₂)+M₃×(h₂-hₓ)',
      m: ['0', '2', '4'],
      e: ['0']
    },
    {
      k: 2,
      t: '10',
      i: '/10_correct.png',
      f: 'Q=M₁×(h₁-h₂)+((M₃+(M₁-M₂))×(h₂-hₓ)',
      m: ['0', '1', '3'],
      e: ['0']
    },
  ]
};

export const trubo_opt = [{ 'id': 1, text: 'Прямой' }, { 'id': 2, text: 'Обратный' }, { id: 3, text: 'Тупиковый' }];

export const massMethodList = {
  '0': 'Нет контроля и коррекции масс. Архивируются фактические значения масс и энергии.',
  '1': 'Контролируется повышение часовой массы обратной трубы над массой подающей трубы. Если оно больше величины максимального небаланса, то устанавливается ошибка баланса масс. Коррекции масс не производится.',
  '2': 'Контролируется абсолютное значение небаланса часовых масс подающей и обратной трубы. Если оно больше величины максимального небаланса, то устанавливается ошибка баланса масс. Коррекции масс не производится.',
  '3': 'Контролируется превышение часовой массы обратной трубы над массой подающей трубы. Если оно больше величины максимального небаланса, то устанавливается ошибка баланса масс. Коррекция масс производится, когда часовая масса обратной трубы превышает часовую массу подающей трубы, но не более чем на величину максимального небаланса. Часовым массам присваивается среднее арифметическое значение реальных часовых масс. Для систем с отбором воды.',
  '4': 'Контролируется абсолютное значение небаланса часовых масс подающей и обратной трубы. Если оно больше величины максимального небаланса, то устанавливается ошибка баланса масс. Коррекция масс производится, когда значение небаланса часовых масс не превышает величину максимального небаланса. Часовым массам присваивается среднее арифметическое значение реальных масс.',
};

export const energyMethodList = {
  '0': 'Нет контроля и коррекции энергии.',
  '1': 'Если часовое значение энергии потерянной/отобранной воды отрицательное, устанавливается ошибка баланса энергии.',
  '2': 'Если часовое значение энергии потерянной/отобранной воды отрицательное, устанавливается ошибка баланса энергии, и часовое приращение энергии потерянной/отобранной воды обнуляется.',
  '3': 'Учет энергии горячего водоснабжения, осуществляется по обратному трубопроводу, при перекрытой подаче. Для типов приборов 6 и 7. Энергии израсходованной/потерянной воды присваивается ее абсолютное значение.'
};
